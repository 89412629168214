import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CashBack from 'images/carousel-items/7.png'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promo/KaDigipayCashBack`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Ka-Digipay Cashback',
  },
  {
    property: 'og:description',
    content: 'This is your chance to earn more, Ka-Digipay!',
  },
  {
    property: 'og:image',
    content: CashBack,
  },
]

const Promo4 = () => (
  <div>
    <Helmet title="Ka-Digipay Cashback" meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <div className="promo-page">
      <h1 align="center" className="font-weight-bold">
        KA-DIGIPAY CASHBACK
      </h1>
      <Image
        src={CashBack}
        width="100%"
        align="center"
        className="promo-image"
      />
      <h4>HOW TO JOIN</h4>
      <ol>
        <li>
          <span id="promo-bold">‘KA-DIGIPAY CASHBACK’</span> is from July 1 to
          December 31, 2019 only.
        </li>
        <li>Open to Filipinos aged 18 years and above.</li>
        <li>
          To qualify, sign up as Digipay Agent with a minimum top-up amount of
          P100 from July 1 - December 31, 2019. To get as much as P300 Cashback,
          provide the following documents upon sign up:
        </li>
        <ul className="promo-unbulleted">
          <li>P100 Cashback - DTI/ SEC Permit of your business</li>
          <li>
            P200 Cashback - Physical Store (with photo) with Barangay Permit
          </li>
          <li>P300 Cashback - Mayor’s / Business Permit </li>
        </ul>
      </ol>
      <hr />

      <h4>HOW TO CLAIM CASHBACK:</h4>
      <ul>
        <li>
          Upon sign up, submission of the necessary documents, and creation of
          Digipay Agent account, Digipay will credit the respective Cashback as
          Digipay credits through the Digipay app.{' '}
        </li>
        <ul className="promo-unbulleted">
          <li>P100 Cashback - DTI/ SEC Permit of your business</li>
          <li>
            P200 Cashback - Physical Store (with photo) with Barangay Permit
          </li>
          <li>P300 Cashback - Mayor’s / Business Permit</li>
        </ul>
        <li>
          Cashback crediting will take up to 5 business days upon receipt of
          complete document requirements.
        </li>
      </ul>
      <hr />

      <h4>TERMS & CONDITIONS </h4>
      <ol>
        <li>
          The promoter is FSG Technology Ventures Inc., doing business under the
          name “Digipay”, with registered office address at 6F, G Business Hub,
          728 Shaw Boulevard, Mandaluyong City.
        </li>
        <li>
          The promotion is open to residents of the Philippines aged 18 years
          and above.
        </li>
        <li>
          By entering this promotion, an entrant is indicating his/her agreement
          to be bound by these terms and conditions.
        </li>
        <li>The rules of the promotion and how to enter are as follows:</li>
        <ul>
          <li>
            {' '}
            Sign up as Digipay Agent with a minimum top-up amount of P100 from
            July 1 - December 31, 2019 to get as much as P300 Cashback. Provide
            the following documents upon sign up to be eligible:
          </li>
          <ul className="promo-unbulleted">
            <li>P100 Cashback - DTI/ SEC Permit of your business</li>
            <li>
              P200 Cashback - Physical Store (with photo) with Barangay Permit
            </li>
            <li>P300 Cashback - Mayor’s / Business Permit</li>
          </ul>
          <li>
            Upon sign up and submission of the necessary documents, Digipay will
            credit the respective Cashback as Digipay credits through the
            Digipay app.{' '}
          </li>
          <li>
            {' '}
            Cashback crediting will take up to 5 business days upon receipt of
            complete document requirements.
          </li>
        </ul>
        <li>Cashbacks are not transferable.</li>
        <li>
          Digipay Agents may claim their cashbacks within 60 days from receipt
          of the registered mail and document requirements.
        </li>
        <li>
          Digipay agents agree to the use of his/her name and image in any
          publicity material. Any personal data relating to the Digipay Agents
          or any other entrants will be used solely in accordance with current
          Philippine data protection legislation and will not be disclosed to a
          third party without the entrant’s prior consent.
        </li>
        <li>
          Any personal data relating to the Digipay Agents or any other entrants
          will be used solely in accordance with current Philippine data
          protection legislation and will not be disclosed to a third party
          without the entrant’s prior consent.
        </li>
      </ol>
    </div>
    <Footer />
  </div>
)
export default Promo4
